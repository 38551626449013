<template>
    <div class="all-topics">
        <BackBar :pageTit="headTit" />
        <div class="integral_head">
            <div>{{data.me.goal.goal}}</div>
            <p>可用积分</p>
        </div>
        <div class="rule">
            <div class="rule_tit">积分获取规则</div>
            <ul>
                <li>
                    <div class="div1"></div>
                    <div class="div2">可获得积分</div>
                    <div class="div3">每日上限</div>
                </li>
                <li v-for="item in data.rows" :key="item.id">
                    <div class="div1">{{item.title}}</div>
                    <div class="div2">+{{item.v}}</div>
                    <div class="div3">{{item.daily_max}}次</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import BackBar from "@/components/BackBar.vue";
import Api from '@/api'
import Vue from 'vue'
export default {
    name:'Integral',
    data(){
        return {
            headTit:'积分',
            data:{}
        }
    },
    mounted(){
        var that=this
        Api.go('my_goal',false,(res)=>{
            that.data=res
        })
    },
    components:{

        BackBar,
    }
}
</script>

<style lang="less" scoped>
    .integral_head{
        width: 100%;
        height: 3.22rem;
        background:url(~@/assets/img/jf-head.png) no-repeat center center/cover;
        div{
            font-size: .48rem;
            color: #fff;
            font-weight: bold;
            text-align: center;
            padding-top: .66rem;
        }
        p{
            font-size: .22rem;
            color: #fff;
            text-align: center;
        }
    }
    .rule{
        background: #fff;
        padding:0 .32rem;
        .rule_tit{
            font-size: .32rem;
            color: #333;
            padding: .32rem 0;
            border-bottom: 1px solid rgba(0, 0, 0, .1);
            font-weight: bold;
        }
        ul{
            padding: .32rem 0;
            li{
                display: flex;
                align-items: center;
                justify-content:space-between;
                div{
                    font-size: .28rem;
                    color: #333;
                    text-align: center;
                    margin-bottom: .32rem;
                    &.div1{
                        width: 2rem;
                        text-align: left;
                    }
                    &.div2{
                        width: 1.5rem; 
                    }
                    &.div3{
                        width: 1.2rem; 
                    }
                }
                &:first-child {
                    div{
                        color: #999;
                    }
                }
            }
        }
    }
</style>